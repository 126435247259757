.drop_area {
    height: 300px;
}

.guZdik {
    height: 30px !important;
}

.dmajdp>span {
    color: #CCC !important
}

.bulkuploadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 8px;
    border-radius: 4px;
}