.fontlocation {
  font-size: 11px !important;
}

.containerLocation {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}